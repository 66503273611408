import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PublicCasePersonResource } from '../../models/resources/public/public-case-person.resource';
import { PublicDocumentCreateResource } from './../../models/resources/public/public-document-create.resource';




@Injectable()
export class ApiPublicService {
    private readonly endpoint = environment.apiUrl + 'public/';

    constructor(
        private _http: HttpClient,
    ) {}

    // CasePerson
    public getCasePersonByAccessKey(accessKey: string): Observable<PublicCasePersonResource> {
        return this._http.get<PublicCasePersonResource>(`${this.endpoint}${accessKey}/caseperson`).pipe(map((result) => result));
    }

    // Document
    public createDocument(accessKey: string, createResource: PublicDocumentCreateResource): Observable<any> {
        return this._http.post<any>(`${this.endpoint}${accessKey}/documents`, createResource).pipe(map((result) => result));
    }

    // DocumentCopy
    public documentCopyApprove(accessKey: string): Observable<any> {
        return this._http.get<any>(`${this.endpoint}documentcopy/${accessKey}/approve`).pipe(map((result) => result));
    }
}
