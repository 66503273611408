import { QueryResourceBase } from "../query-resource-base.resource";

export class DocumentTypeQueryResource implements QueryResourceBase {
    public name: string;
    public description: string;
    
    public sortBy: string;
    public isSortAscending: boolean;
    public page: number;
    public pageSize: number;
}