import { HttpErrorResponse } from '@angular/common/http';
import { isArray, isString } from 'util';
import { ToastrService } from 'ngx-toastr';

export class APIUtils {
    public static objectToQueryString(obj: any) {
        const parts = new Array<string>();

        for (const property in obj) {
            if (!obj.hasOwnProperty(property)) {
                continue;
            }

            let value = obj[property];
            if (value !== null && value !== undefined) {

                // Converts dates to strings
                if (value instanceof Date) {
                    value = value.toLocaleString();
                }

                parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
            }
        }

        return '?' + parts.join('&');
    }

    public static parseHttpErrorResponse(toastrService: ToastrService, error: HttpErrorResponse, title: string): void {
        switch (error.status) {
            case 400: toastrService.error(APIUtils.parseBadRequest(error), title); break;
            case 409: toastrService.error(APIUtils.parseBadRequest(error), title); break;
            default: toastrService.error(error.message, title); break;
        }
    }

    private static parseBadRequest(error: HttpErrorResponse): string {
        let message: string;
        let first = true;

        if (isString(error.error)) {
            // String
            message = error.error;
        } else {
            // ErrorModel
            Object.keys(error.error).forEach((key) => {
                if (isArray(error.error[key])) {
                    if (first) {
                        first = false;
                        message = `${error.error[key][0]}`;
                    } else {
                        message += `\n${error.error[key][0]}`;
                    }
                    console.log(message);
                }
            });
        }

        return message;
    }
}
