
import {map} from 'rxjs/operators';



import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CaseCreateResource } from '../../models/resources/case/case-create.resource';
import { CaseQueryResource } from '../../models/resources/case/case-query.resource';
import { CaseUpdateResource } from '../../models/resources/case/case-update.resource';
import { DocumentResource } from '../../models/resources/document/document.resource';
import { QueryResultResource } from '../../models/resources/query-result.resource';
import { APIUtils } from '../../utils/api-utils.class';
import { CasePersonCreateResource } from './../../models/resources/case-person/case-person-create.resource';
import { CasePersonResource } from './../../models/resources/case-person/case-person.resource';
import { CaseResource } from './../../models/resources/case/case.resource';
import { DocumentCreateResource } from './../../models/resources/document/document-create.resource';
import { ApiCasePersonService } from './api-case-person.service';
import { ApiDocumentService } from './api-document.service';

@Injectable()
export class ApiCaseService {
    private readonly endpoint = environment.apiUrl + 'cases/';

    constructor(
        private _http: HttpClient,
    ) {}

    private mapObject(resource: CaseResource): CaseResource {
        resource.createdAt = new Date(resource.createdAt);
        resource.modifiedAt = new Date(resource.modifiedAt);
        resource.deleteAt = new Date(resource.deleteAt);

        return resource;
    }

    public get(caseId: number): Observable<CaseResource> {
        return this._http.get<CaseResource>(`${this.endpoint}${caseId}`).pipe(map((result) => this.mapObject(result)));
    }

    public getAll(queryResource: CaseQueryResource): Observable<QueryResultResource<CaseResource>> {
        const query = `${this.endpoint}${APIUtils.objectToQueryString(queryResource)}`;
        return this._http.get<QueryResultResource<CaseResource>>(query).pipe(map((result) => {
            result.items.forEach((item) => item = this.mapObject(item));
            return result;
        }));
    }

    public create(createResource: CaseCreateResource): Observable<CaseResource> {
        return this._http.post<CaseResource>(`${this.endpoint}`, createResource).pipe(map((result) => this.mapObject(result)));
    }

    public update(caseId: number, updateResource: CaseUpdateResource): Observable<CaseResource> {
        return this._http.put<CaseResource>(`${this.endpoint}${caseId}`, updateResource).pipe(map((result) => this.mapObject(result)));
    }

    public delete(caseId: number): Observable<string> {
        return this._http.delete(`${this.endpoint}${caseId}`, { responseType: 'text' }).pipe(map((result) => result));
    }

    /*
     *  Relations - Documents
     */
    public getAllDocuments(itemId: number): Observable<QueryResultResource<DocumentResource>> {
        return this._http.get<QueryResultResource<DocumentResource>>(`${this.endpoint}${itemId}/documents`).pipe(map((result) => {
            result.items.forEach((item) => item = ApiDocumentService.mapObject(item));
            return result;
        }));
    }

    public createDocument(itemId: number, createResource: DocumentCreateResource): Observable<DocumentResource> {
        return this._http.post<DocumentResource>(`${this.endpoint}${itemId}/documents`, createResource)
            .pipe(map((result) => ApiDocumentService.mapObject(result)));
    }

    /*
     *  Relations - CasePersons
     */
    public getAllCasePersons(itemId: number): Observable<QueryResultResource<CasePersonResource>> {
        return this._http.get<QueryResultResource<CasePersonResource>>(`${this.endpoint}${itemId}/casepersons`).pipe(map((result) => {
            result.items.forEach((item) => item = ApiCasePersonService.mapObject(item));
            return result;
        }));
    }

    public createCasePerson(itemId: number, createResource: CasePersonCreateResource): Observable<CasePersonResource> {
        return this._http.post<CasePersonResource>(`${this.endpoint}${itemId}/casepersons`, createResource).pipe(
            map((result) => ApiCasePersonService.mapObject(result)));
    }
}
