import { Buffer } from 'buffer';

export class Base64Utils {
    public static stringToBase64(string):string {
        var str = new Buffer(string);
        return str.toString('base64');
    }

    public static bufferToBase64(buffer) {
        var buf = new Buffer(buffer);
        return buf.toString('base64');
    }
    
    public static hexToBase64(hexStr:string) {
        var buffer = new Buffer(hexStr, 'hex');
        return buffer.toString('base64')
    }

    public static base64ToBuffer(base64Str:string) {
        return new Buffer(base64Str, 'base64');
    }
    
    public static base64ToUint8Array(base64Str:string) {
        var buffer = new Buffer(base64Str, 'base64');
        return new Uint8Array(buffer);
    }
}