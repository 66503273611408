import { Component, OnInit } from '@angular/core';

import { KeyStoreService } from '../../services/key-store.service';

@Component({
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
    // Fields
    public savingKey: boolean;
    public hasPrivateKey: boolean;

    public privateKey: string;

    constructor(
        private keyStoreService: KeyStoreService,
    ) {
    }

    public ngOnInit(): void {
        this.hasPrivateKey = this.keyStoreService.hasKey();
    }

    /**
     * Tryes to install a private key
     */
    private async installPrivateKey() {
        this.savingKey = true;
        const result = await this.keyStoreService.registerKey(this.privateKey);
        this.savingKey = false;

        this.hasPrivateKey = this.keyStoreService.hasKey();
    }

    /**
     * Will reset the key from localstorage
     */
    public resetPrivateKey() {
        if (confirm('Are you sure you wanna reset your key store?')) {
            this.keyStoreService.resetKey();
            window.location.reload();
        }
    }
}
