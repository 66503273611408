export class CommonUtils {
    /**
     * 
     * @param string 
     * @param size 
     * @param multiline 
     */
    public static splitString(string:string, size:number, multiline:boolean) {
        var matchAllToken = (multiline == true) ? '[^]' : '.';
        var re = new RegExp(matchAllToken + '{1,' + size + '}', 'g');
        return string.match(re);
    }

    /**
     * Generates a random sting with a fixed a length
     * @param length 
     */
    public static RandomString(length:number):string {
        const PASSPHRASE_KEYS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        // Generating Key
        var passphrase:string = "";
        for (var i = 0; i < length; i++)
            passphrase += PASSPHRASE_KEYS.charAt(Math.floor(Math.random() * PASSPHRASE_KEYS.length));
      
        return passphrase;
    }

    public static hexStringToUint8Array(hexString:string):Uint8Array {
        if (hexString.length % 2 != 0)
            throw "Invalid hexString";
        var arrayBuffer = new Uint8Array(hexString.length / 2);
    
        for (var i = 0; i < hexString.length; i += 2) {
            var byteValue = parseInt(hexString.substr(i, 2), 16);
            if (byteValue == NaN)
                throw "Invalid hexString";
            arrayBuffer[i/2] = byteValue;
        }
    
        return arrayBuffer;
    }

    public static asciiToUint8Array(str:string):Uint8Array {
        var chars = [];
        for (var i = 0; i < str.length; ++i)
            chars.push(str.charCodeAt(i));
        return new Uint8Array(chars);
    }

    public static bytesToASCIIString(bytes):string {
        return String.fromCharCode.apply(null, new Uint8Array(bytes));
    }

    public static bytesToHexString(bytes):string {
        if (!bytes)
            return null;

        bytes = new Uint8Array(bytes);
        var hexBytes = [];

        for (var i = 0; i < bytes.length; ++i) {
            var byteString = bytes[i].toString(16);
            if (byteString.length < 2)
                byteString = "0" + byteString;
            hexBytes.push(byteString);
        }

        return hexBytes.join("");
    }
    
    /**
     * detect IE
     * returns version of IE or false, if browser is not Internet Explorer
     */
    public static detectIE(): any {
        var ua = window.navigator.userAgent;
      
        // Test values; Uncomment to check result …
      
        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
      
        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
      
        // IE 12 / Spartan
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
      
        // Edge (IE 12+)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
      
        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
          // IE 10 or older => return version number
          return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
      
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
          // IE 11 => return version number
          var rv = ua.indexOf('rv:');
          return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
      
        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
          // Edge (IE 12+) => return version number
          return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
      
        // other browser
        return false;
    }
}