
import {map} from 'rxjs/operators';



import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DocumentTypeCreateResource } from '../../models/resources/document-type/document-type-create.resource';
import { DocumentTypeQueryResource } from '../../models/resources/document-type/document-type-query.resource';
import { DocumentTypeUpdateResource } from '../../models/resources/document-type/document-type-update.resource';
import { QueryResultResource } from '../../models/resources/query-result.resource';
import { APIUtils } from '../../utils/api-utils.class';
import { DocumentTypeResource } from './../../models/resources/document-type/document-type.resource';

@Injectable()
export class ApiDocumentTypeService {
    private readonly endpoint = environment.apiUrl + 'documenttypes/';
    
    constructor(
        private _http: HttpClient,
    ) {}

    private mapObject(resource:DocumentTypeResource):DocumentTypeResource {
        resource.createdAt = new Date(resource.createdAt);
        resource.modifiedAt = new Date(resource.modifiedAt);

        return resource;
    }

    public get(caseId:number):Observable<DocumentTypeResource> {
        return this._http.get<DocumentTypeResource>(`${this.endpoint}${caseId}`).pipe(map((result) => this.mapObject(result)));
    }

    public getAll(queryResource:DocumentTypeQueryResource):Observable<QueryResultResource<DocumentTypeResource>> {
        return this._http.get<QueryResultResource<DocumentTypeResource>>(`${this.endpoint}${APIUtils.objectToQueryString(queryResource)}`).pipe(map((result) => {
            return result;
        }));
    }

    public create(createResource:DocumentTypeCreateResource):Observable<DocumentTypeResource> {
        return this._http.post<DocumentTypeResource>(`${this.endpoint}`, createResource).pipe(map((result) => this.mapObject(result)));
    }

    public update(documentTypeId:number, updateResource:DocumentTypeUpdateResource):Observable<DocumentTypeResource> {
        return this._http.put<DocumentTypeResource>(`${this.endpoint}${documentTypeId}`, updateResource).pipe(map((result) => this.mapObject(result)));
    }
}