import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DocumentDataResource } from '../../models/resources/document-data/document-data.resource';
import { DocumentResource } from '../../models/resources/document/document.resource';

@Injectable()
export class ApiDocumentService {
    private readonly endpoint = environment.apiUrl + 'documents/';

    constructor(
        private _http: HttpClient,
    ) {}

    public static mapObject(resource: DocumentResource): DocumentResource {
        resource.createdAt = new Date(resource.createdAt);
        resource.modifiedAt = new Date(resource.modifiedAt);
        return resource;
    }

    public get(itemId: number): Observable<DocumentResource> {
        return this._http.get<DocumentResource>(`${this.endpoint}${itemId}`).pipe(map((result) => ApiDocumentService.mapObject(result)));
    }

    public getData(itemId: number): Observable<DocumentDataResource> {
        return this._http.get<DocumentDataResource>(`${this.endpoint}${itemId}/data`).pipe(map((result) => result));
    }

    public delete(documentId: number): Observable<string> {
        return this._http.delete(`${this.endpoint}${documentId}`, { responseType: 'text' }).pipe(map((result) => result));
    }

    public approve(documentId: number): Observable<DocumentResource> {
        return this._http.put<DocumentResource>(`${this.endpoint}${documentId}/approve`, {}).pipe(
            map((result) => ApiDocumentService.mapObject(result)));
    }

    public disapprove(documentId: number): Observable<any> {
        return this._http.put(`${this.endpoint}${documentId}/disapprove`, {}).pipe(map((result) => result));
    }
}
