import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';

import { CaseResource } from '../../../../models/resources/case/case.resource';
import { DocumentCreateResource } from '../../../../models/resources/document/document-create.resource';
import { ApiDocumentService } from '../../../../services/api/api-document.service';
import { KeyStoreService } from '../../../../services/key-store.service';
import { AESCryptUtils } from '../../../../utils/aes-crypt-utils.class';
import { Base64Utils } from '../../../../utils/base64-utils.class';
import { CommonUtils } from '../../../../utils/common-utils.class';
import { FileUtils } from '../../../../utils/file-utils.class';
import { RSAUtils } from '../../../../utils/rsa-utils.class';
import { DocumentDataResource } from './../../../../models/resources/document-data/document-data.resource';
import { DocumentResource } from './../../../../models/resources/document/document.resource';
import { QueryResultResource } from './../../../../models/resources/query-result.resource';
import { ApiApiService } from './../../../../services/api/api-api.service';
import { ApiCaseService } from './../../../../services/api/api-case.service';

@Component({
    templateUrl: './admin-case-view.component.html',
    styleUrls: ['./admin-case-view.component.css']
})
export class AdminCaseViewComponent implements OnInit {
    // Fields
    public itemId: any;
    public item: CaseResource;

    public documentsQueryResult: QueryResultResource<DocumentResource>;

    public isLoading: boolean;

    // Decrypt Properties
    public documentDecryptedWindow: boolean;
    public documentDecrypted: DocumentDataResource;
    public documentDecryptedData: any;
    public documentDecryptedBlobData: any;
    public isIE: boolean;

    // Upload File Properties
    public file: File;
    public fileSizeLimit = 10; // In MB
    public fileAllowedTypes = ['png', 'jpg', 'bmp', 'txt', 'pdf'];
    public fileUploading = false;

    constructor(
        public _location: Location,
        private _route: ActivatedRoute,
        private _domSanitizer: DomSanitizer,
        private toastrService: ToastrService,
        private translate: TranslateService,
        private apiApiService: ApiApiService,
        private apiCaseService: ApiCaseService,
        private apiDocumentService: ApiDocumentService,
        private keyStoreService: KeyStoreService,
    ) {
        this.isIE = CommonUtils.detectIE();
        this.isLoading = false;
    }

    public ngOnInit(): void {
        this.itemId = this._route.snapshot.params['caseId'];
        if (this.itemId) {
            this.pullCase();
            this.pullDocuments();
        }

    }

    /**
     * Pulls Case
     */
    private pullCase(): void {
        this.isLoading = true;
        this.apiCaseService.get(this.itemId).subscribe((itemResult) => {
            this.item = itemResult;
            this.isLoading = false;
        }, (error: HttpErrorResponse) => {
            this.toastrService.error('Failed to get Case!', error.message);
            this.isLoading = false;
        });
    }

    /**
     * Pulls Documents
     */
    private pullDocuments() {
        this.apiCaseService.getAllDocuments(this.itemId).subscribe((documents) => {
            this.documentsQueryResult = documents;
        }, (error: HttpErrorResponse) => {
            this.toastrService.error('Failed to get Documents!', error.message);
        });
    }

    /**
     * Will try to delete the document
     * @param item
     */
    public delete(document: DocumentResource): void {
        this.translate.get([
            'toasty.error.deletedtitle',
            'toasty.error.deletedmsg',
            'toasty.document.deletedtitle',
            'toasty.document.deletedmsg',
        ]).subscribe(translations => {
            this.apiDocumentService.delete(document.id).subscribe((result) => {
                this.toastrService.success(translations['toasty.document.deletedmsg'], translations['toasty.document.deletedtitle']);
                this.pullDocuments();
            }, (error: HttpErrorResponse) => {
                this.toastrService.error(error.message, 'Failed to delete Document!');
                this.pullDocuments();
            });
        });
    }

    /**
     * Approve a document
     */
    public approveDocument(document: DocumentDataResource): void {
        this.apiDocumentService.approve(document.document.id).subscribe((itemResult) => {
            this.pullDocuments();
            this.closeDecryptedWindow();

            this.toastrService.success('', 'Document Approved');
        }, (error: HttpErrorResponse) => {
            this.closeDecryptedWindow();
            this.toastrService.error(error.message, 'Failed to approved document!');
        });
    }

    /**
     * Disapprove a document
     */
    public disapproveDocument(document: DocumentDataResource): void {
        this.apiDocumentService.disapprove(document.document.id).subscribe((item) => {
            this.pullDocuments();
            this.closeDecryptedWindow();

            this.toastrService.success('', 'Document Disapproved');
        }, (error) => {
            this.closeDecryptedWindow();
            this.toastrService.error(error.message, 'Failed to disapproved document!');
        });
    }

    /**
     * Decrypt Method
     */
    private delay(ms: number): Promise<any> {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    private async decryptDocument(documentResource: DocumentResource) {
        this.apiDocumentService.getData(documentResource.id).subscribe(async (itemResult) => {
            this.documentDecrypted = itemResult;
            this.documentDecryptedWindow = true;
            this.documentDecryptedData = 'decrypting';

            await this.delay(250);

            // Decrypting Key/Iv
            const privateKey = await this.keyStoreService.openKey();
            const keyBase64 = RSAUtils.DecryptText(privateKey, documentResource.key);
            const ivBase64 = RSAUtils.DecryptText(privateKey, documentResource.iv);

            // Decrypting
            const decryptedMessage = AESCryptUtils.cryptoJSDecrypt(keyBase64, ivBase64, this.documentDecrypted.data);
            const fileBase64 = decryptedMessage.toString(CryptoJS.enc.Base64);

            // Making Base64
            const fileExtention = documentResource.fileExtention.toLocaleLowerCase();
            if (fileExtention !== 'pdf') {
                const decryptData = this._domSanitizer.bypassSecurityTrustResourceUrl(`data:image/${fileExtention};base64, ${fileBase64}`);
                this.documentDecryptedData = decryptData;
            } else {
                const byteCharacters = atob(fileBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                this.documentDecryptedBlobData = new Blob([byteArray], { type: 'application/pdf' });

                const decryptData = this._domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.documentDecryptedBlobData));
                this.documentDecryptedData = decryptData;
            }
        }, (error: HttpErrorResponse) => {
            this.closeDecryptedWindow();
            this.toastrService.error('Failed to get decrypt document!', error.message);
        });
    }

    private closeDecryptedWindow() {
        this.documentDecrypted = null;
        this.documentDecryptedWindow = false;
        this.documentDecryptedData = null;
        this.documentDecryptedBlobData = null;
    }

    private downloadFile() {
        if (this.isIE) {
            window.navigator.msSaveOrOpenBlob(this.documentDecryptedBlobData);
        } else {
            const url = window.URL.createObjectURL(this.documentDecryptedBlobData);
            window.location.assign(url);
        }
    }

    /**
     * Opens the file browser
     */
    public uploadDocument() {
        const fileInput = document.getElementById('browsefile');
        fileInput.click();
    }

    public fileBrowserChanged(file: File) {
        this.file = file;

        // Size limit
        const fileSize = file.size / 1024 / 1024;
        if (fileSize >= this.fileSizeLimit) {
            const errorMessage = `File is to big, ${this.fileSizeLimit} MB is the limit, file is ${Math.round(fileSize * 100) / 100} MB`;
            this.toastrService.error(errorMessage, 'File size is to large!');
            return;
        }

        // File Type
        const fileExtention = file.name.substr(file.name.lastIndexOf('.') + 1).toLocaleLowerCase();
        if (!(this.fileAllowedTypes.indexOf(fileExtention) > -1)) {
            let allowedTypes: string;
            this.fileAllowedTypes.forEach(fileType => allowedTypes +=  ' .' + fileType);

            const errorMessage = `'Wrong file type only ${allowedTypes} are allowed',`;
            this.toastrService.error(errorMessage, 'File is wrong type!');
            return;
        }

        // Encrypting file and uploading
        this.encryptFile(file);
    }

    /**
     * Encrypts file
     */
    private encryptFile(file: File) {
        const retVal = prompt('File navn', file.name);
        this.fileUploading = true;
        this.apiApiService.getPublicKey().subscribe(async (publicKey: string) => {
            // Generating / Encrypting Key
            const key = AESCryptUtils.generateKey();
            const keyBase64 = Base64Utils.bufferToBase64(key);
            const keyEncrypted = RSAUtils.EncryptText(publicKey, keyBase64);

            // Generating / Encrypting IV
            const iv = AESCryptUtils.generateIv();
            const ivBase64 = Base64Utils.bufferToBase64(iv);
            const ivEncrypted = RSAUtils.EncryptText(publicKey, ivBase64);

            // Encrypting
            const fileBase64 = await FileUtils.fileToBase64String(file);
            const fileWordArray = CryptoJS.enc.Base64.parse(fileBase64);
            const cipherWordArray = AESCryptUtils.cryptoJSEncrypt(keyBase64, ivBase64, fileWordArray);

            // Creating DocumentCreateModel
            const documentCreateResource = new DocumentCreateResource();
            documentCreateResource.key = keyEncrypted;
            documentCreateResource.iv = ivEncrypted;
            documentCreateResource.fileData = cipherWordArray.toString();
            documentCreateResource.fileName = retVal ? retVal : file.name;
            documentCreateResource.fileExtention = file.name.substr(file.name.lastIndexOf('.') + 1);
            documentCreateResource.fileSize = file.size;

            // Uploading
            this.apiCaseService.createDocument(this.item.id, documentCreateResource).subscribe((documentResource) => {
                this.file = null;
                this.fileUploading = false;
                this.pullDocuments();
            }, (error) => {
                this.file = null;
                this.fileUploading = false;
                this.toastrService.error(error.message, 'Failed to upload file!');
            });
        }, (error) => {
            this.file = null;
            this.fileUploading = false;
            this.toastrService.error(error.message, 'Failed to public key!');
        });
    }
}
