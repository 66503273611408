import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DocumentCopyCreateResource } from '../../models/resources/document-copy/document-copy-create.resource';
import { DocumentCopyResource } from '../../models/resources/document-copy/document-copy.resource';
import { PersonQueryResource } from '../../models/resources/person/person-query.resource';
import { QueryResultResource } from '../../models/resources/query-result.resource';
import { APIUtils } from '../../utils/api-utils.class';


@Injectable()
export class ApiDocumentCopyService {
    private readonly endpoint = environment.apiUrl + 'documentcopys/';

    constructor(
        private _http: HttpClient,
    ) {}

    private mapObject(resource: DocumentCopyResource): DocumentCopyResource {
        resource.createdAt = new Date(resource.createdAt);
        resource.modifiedAt = new Date(resource.modifiedAt);

        return resource;
    }

    public get(itemId: number): Observable<DocumentCopyResource> {
        return this._http.get<DocumentCopyResource>(`${this.endpoint}${itemId}`).pipe(map((result) => this.mapObject(result)));
    }

    public getAll(queryResource: PersonQueryResource): Observable<QueryResultResource<DocumentCopyResource>> {
        const query = `${this.endpoint}${APIUtils.objectToQueryString(queryResource)}`;
        return this._http.get<QueryResultResource<DocumentCopyResource>>(query).pipe(map((result) => {
            result.items.forEach((item) => item = this.mapObject(item));
            return result;
        }));
    }

    public create(createResource: DocumentCopyCreateResource): Observable<DocumentCopyResource> {
        return this._http.post<DocumentCopyResource>(`${this.endpoint}`, createResource).pipe(map((result) => this.mapObject(result)));
    }
}
