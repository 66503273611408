import { ResourceBase } from '../resource-base.resource';
import { CasePersonResource } from '../case-person/case-person.resource';
import { LanguageEnum } from '../language.enum';

export class PersonResource extends ResourceBase {
    public firstName: string;
    public lastName: string;
    public birthdate: Date;
    public socialSecurityNumber: string;
    public phoneNumber: string;
    public email: string;
    public alternativeEmail: string;
    public address: string;
    public zipcode: string;
    public city: string;
    public country: string;

    public language: LanguageEnum;

    // Relations
    public casePersons: Array<CasePersonResource>;

    // Client Only
    public displayName: string;
}
