
import {map} from 'rxjs/operators';



import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CasePersonCreateResource } from '../../models/resources/case-person/case-person-create.resource';
import { CasePersonUpdateResource } from '../../models/resources/case-person/case-person-update.resource';
import { DocumentResource } from '../../models/resources/document/document.resource';
import { CasePersonResource } from './../../models/resources/case-person/case-person.resource';
import { ApiDocumentService } from './api-document.service';

@Injectable()
export class ApiCasePersonService {
    private readonly endpoint = environment.apiUrl + 'casepersons/';

    constructor(
        private _http: HttpClient,
    ) {}

    public static mapObject(item: CasePersonResource): CasePersonResource {
        item.createdAt = new Date(item.createdAt);
        item.modifiedAt = new Date(item.modifiedAt);

        return item;
    }

    public create(createResource: CasePersonCreateResource): Observable<CasePersonResource> {
        return this._http.post<CasePersonResource>(`${this.endpoint}`, createResource).pipe(
            map((result) => ApiCasePersonService.mapObject(result)));
    }

    public update(itemId: number, updateResource: CasePersonUpdateResource): Observable<CasePersonResource> {
        return this._http.put<CasePersonResource>(`${this.endpoint}${itemId}`, updateResource).pipe(
            map((result) => ApiCasePersonService.mapObject(result)));
    }

    public delete(itemId: number): Observable<string> {
        return this._http.delete(`${this.endpoint}${itemId}`, { responseType: 'text' }).pipe(map((result) => result));
    }

    public resendUploadLink(caseId: number): Observable<CasePersonResource> {
        return this._http.post<CasePersonResource>(`${this.endpoint}${caseId}/resenduploadlink`, {}).pipe(
            map((result) => ApiCasePersonService.mapObject(result)));
    }

    // Relation - Documents
    public getAllDocuments(itemId: number): Observable<Array<DocumentResource>> {
        return this._http.get<Array<DocumentResource>>(`${this.endpoint}${itemId}/documents`).pipe(map((result) => {
            result.forEach((item) => item = ApiDocumentService.mapObject(item));
            return result;
        }));
    }
}
