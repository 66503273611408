import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { EnumUtils } from '../../../extentions/enum-utils';
import { CaseDivisionEnum } from '../../../models/resources/case/case-division.enum';
import { CaseQueryResource } from './../../../models/resources/case/case-query.resource';
import { CaseUpdateResource } from './../../../models/resources/case/case-update.resource';
import { CaseResource } from './../../../models/resources/case/case.resource';
import { QueryResultResource } from './../../../models/resources/query-result.resource';
import { ApiCaseService } from './../../../services/api/api-case.service';

@Component({
    templateUrl: './admin-cases.component.html'
})
export class AdminCasesComponent implements OnInit {
    // Bindable Fields
    public isLoading: boolean;
    public query: CaseQueryResource;
    public queryResult: QueryResultResource<CaseResource>;
    public queryCount: number;

    public searchText: string;

    public division: any;
    public divisions: any;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private apiCaseService: ApiCaseService,
        private toastrService: ToastrService,
        private translate: TranslateService,
    ) {
        this.divisions = EnumUtils.getNamesAndValues<CaseDivisionEnum>(CaseDivisionEnum);
        this.translate.onLangChange.subscribe(() => {
            this.divisions = EnumUtils.getNamesAndValues<CaseDivisionEnum>(CaseDivisionEnum);
            this.translateDivisions();
        });
        
        this.translateDivisions();
    }

    public ngOnInit(): void {
        this.query = new CaseQueryResource();
        this.query.page = 1;
        this.query.pageSize = 15;
        this.query.sortBy = 'caseNumber';
        this.query.isSortAscending = true;

        //this.pullCases();

        this._route.queryParams.subscribe(params => {

            if(params.searchText) {
                this.searchText = params.searchText;
            } else {
                this.searchText = "";
            }

            if(params.division) {
                this.query.division = Number(params.division);
            } else {
                this.query.division = undefined;
            }
            
            if(params.page) {
                this.query.page = Number(params.page);
            } else {
                this.query.page = 1;
            }

            if(params.active) {
                this.query.active = params.active;
            } else {
                this.query.active = undefined
            }

            this.query.caseNumber = params.searchText;
            this.query.casePersonName = params.searchText;
            this.query.casePersonEmail = params.searchText;

            this.pullCases();
        });
    }

    /*
     *  Translations
     */
    private translateDivisions(): void {
        // Translating companyCategories to translated text
        const languageEnumTranslations = [];
        this.divisions.forEach(item => {
            languageEnumTranslations.push('casedivisionenum.' + item.value);
        });
        this.translate.get(languageEnumTranslations).subscribe(translations => {
            this.divisions.forEach(item => {
                item.name = translations['casedivisionenum.' + item.value];
            });
        });
    }

    /**
     * Pulls the cases using the query field
     */
    private pullCases(): void {
        this.isLoading = true;
        this.translate.get([
            'toasty.error.pulltitle',
            'toasty.error.pullmsg',
        ]).subscribe(translations => {
            this.queryResult = null;
            this.apiCaseService.getAll(this.query).subscribe(queryResult => {
                this.queryResult = queryResult;
                this.queryCount = queryResult.count;
                if (this.queryResult.items == null) {
                    this.queryResult.items = new Array<CaseResource>();
                }
                
                this.isLoading = false;
            }, (error: HttpErrorResponse) => {
                this.toastrService.error(error.message, 'Failed to get Cases!');
                this.isLoading = false;
            });
        });
    }

    /**
     * Updates the query and repulls the objects
     */
    public search(): void {
        this.query.caseNumber = this.searchText;
        this.query.casePersonName = this.searchText;
        this.query.casePersonEmail = this.searchText;
        this.query.page = 1;

        this._router.navigate([], {
            queryParams: {
                searchText: this.searchText,
                division: this.query.division,
                active: this.query.active,
                page: this.query.page,
            }, 
            queryParamsHandling: 'merge' 
        });

        this.pullCases();
    }

    /**
     * Will apply a sortBy filter
     */
    public sortBy(sortBy: string): void {
        if(this.query.sortBy === sortBy) {
            this.query.isSortAscending = !this.query.isSortAscending;
        } else {
            this.query.isSortAscending = false;
            this.query.sortBy = sortBy;
        }
        this.pullCases();
    }

    /**
     * Changes the current page
     * @param page
     */
    public pageChange(page): void {
        this.query.page = page;

        this._router.navigate([], {
            queryParams: {
                page: this.query.page,
            }, 
            queryParamsHandling: 'merge' 
        });

        this.pullCases();
    }

    /**
     * Will try to delete the case
     * @param item
     */
    public delete(item: CaseResource): void {
        this.translate.get([
            'toasty.error.deletedtitle',
            'toasty.error.deletedmsg',
            'toasty.case.deletedtitle',
            'toasty.case.deletedmsg',
        ]).subscribe(translations => {
            this.apiCaseService.delete(item.id).subscribe((result) => {
                this.toastrService.success(translations['toasty.case.deletedmsg'], translations['toasty.case.deletedtitle']);
                this.pullCases();
            }, (error: HttpErrorResponse) => {
                this.toastrService.error(error.message, 'Failed to delete Case!');
                this.pullCases();
            });
        });
    }

    /**
     * Will toggle the activated
     * @param item Item to toggle
     */
    public toggleActive(item: CaseResource) {
        item.active = !item.active;
        const updateResource = new CaseUpdateResource(item);
        this.apiCaseService.update(item.id, updateResource).subscribe((itemResult) => {
            this.toastrService.success('', 'Case updated Activation');
        }, (error) => {
            this.toastrService.error(error.message, 'Failed to update Case!');
        });
    }
}
