import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DocumentCopyComponent } from './components/main/document-copy/document-copy.component';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/shared/not-found/not-found.component';

const AppRoutes: Routes = [
    { path: '', component: MainComponent },
    { path: ':accessKey', component: MainComponent },
    { path: 'documentcopy/:accessKey', component: DocumentCopyComponent },
    { path: '**', component: NotFoundComponent }
];

export const AppRouting: ModuleWithProviders = RouterModule.forRoot(AppRoutes);
