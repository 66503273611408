import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'language-selection',
    templateUrl: './language-selection.component.html',
    styleUrls: ['./language-selection.component.css']
})
export class LanguageSelectionComponent implements OnInit {
    // Properties
    public languages;

    constructor(
        private translate:TranslateService
    ) {}

    public ngOnInit(): void { 
        this.languages = this.translate.getLangs();
    }

    public changeLanguage(langauge:string) {
        this.translate.use(langauge);
    }
}
