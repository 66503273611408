export class DocumentCreateResource {
    public key: string;

    public iv: string;

    public fileData: string;

    public fileName: string;

    public fileExtention: string;

    public fileSize: number;
}
