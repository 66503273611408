import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { AdminAuthenticationGuard } from '../../guards/admin-authentication.guard';
import { WindowsAuthInterceptor } from '../../interceptors/windowsauth.interceptor';
import { ApiCaseService } from '../../services/api/api-case.service';
import { ApiDocumentCopyService } from '../../services/api/api-document-copy.service';
import { ApiDocumentTypeService } from '../../services/api/api-document-type.service';
import { ApiDocumentService } from '../../services/api/api-document.service';
import { ApiPersonService } from '../../services/api/api-person.service';
import { ApiUserKeyService } from '../../services/api/api-user-key.service';
import { KeyStoreService } from '../../services/key-store.service';
import { LanguageSelectionModule } from '../shared/language-selection/language-selection.module';
import { PaginationComponent } from '../shared/pagination/pagination.component';
import { ApiCasePersonService } from './../../services/api/api-case-person.service';
import { AdminCaseViewComponent } from './admin-cases/admin-case-view/admin-case-view.component';
import { AdminCaseComponent } from './admin-cases/admin-case/admin-case.component';
import { AdminCasesComponent } from './admin-cases/admin-cases.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminPersonCopyComponent } from './admin-persons/admin-person-copy/admin-person-copy.component';
import { AdminPersonDocumentsComponent } from './admin-persons/admin-person-documents/admin-person-documents.component';
import { AdminPersonComponent } from './admin-persons/admin-person/admin-person.component';
import { AdminPersonsComponent } from './admin-persons/admin-persons.component';
import {
    AdminSettingsDocumentTypesComponent,
} from './admin-settings/admin-settings-document-types/admin-settings-document-types.component';
import { AdminComponent } from './admin.component';
import { AdminRouting } from './admin.routing';
import { CaseStatusBadgeComponent } from './shared/case-status-badge/case-status-badge.component';
import { DocumentStatusBadgeComponent } from './shared/document-status/document-status-badge.component';

@NgModule({
    imports: [
        NgSelectModule,
        FormsModule,
        CommonModule,
        TranslateModule,
        LanguageSelectionModule,
        AdminRouting,
    ],
    declarations: [
        // Components
        AdminComponent,
        AdminHomeComponent,

        // Case Components
        AdminCasesComponent,
        AdminCaseComponent,
        AdminCaseViewComponent,

        // Person Components
        AdminPersonComponent,
        AdminPersonCopyComponent,
        AdminPersonDocumentsComponent,
        AdminPersonsComponent,

        // Settings Components
        AdminSettingsDocumentTypesComponent,

        // Shared
        CaseStatusBadgeComponent,
        DocumentStatusBadgeComponent,
        PaginationComponent
    ],
    providers: [
        // Interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WindowsAuthInterceptor,
            multi: true
        },

        // Gruards
        AdminAuthenticationGuard,

        // Services
        KeyStoreService,
        ApiCaseService,
        ApiCasePersonService,
        ApiDocumentService,
        ApiDocumentCopyService,
        ApiDocumentTypeService,
        ApiPersonService,
        ApiUserKeyService
    ],
    exports: [],
})
export class AdminModule {}
