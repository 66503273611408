
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UserKeyCreateResource } from '../../models/resources/user-key/user-key-create.resource';
import { UserKeyResource } from '../../models/resources/user-key/user-key.resource';

@Injectable()
export class ApiUserKeyService {
    private readonly endpoint = environment.apiUrl + 'userkeys/';

    constructor(
        private _http: HttpClient,
    ) {}

    private mapObject(resource: UserKeyResource): UserKeyResource {
        resource.createdAt = new Date(resource.createdAt);
        resource.modifiedAt = new Date(resource.modifiedAt);

        return resource;
    }

    public getByMachineId(machineId: string): Observable<UserKeyResource> {
        return this._http.get<UserKeyResource>(`${this.endpoint}${machineId}/machineId`).pipe(map((result) => this.mapObject(result)));
    }

    public create(createResource: UserKeyCreateResource): Observable<UserKeyResource> {
        return this._http.post<UserKeyResource>(`${this.endpoint}`, createResource).pipe(map((result) => this.mapObject(result)));
    }
}
