export class PublicDocumentCreateResource {
    public key: string;
    public iv: string;
    public fileData: string;
    public fileName: string;
    public fileExtention: string;
    public fileSize: number;

    public documentTypeId: number;
    public documentTypeName: string;    // Client Only

    public uploaded: boolean;       // Client Only
    public uploading: boolean;      // Client Only
}
