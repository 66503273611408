import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiPublicService } from '../../../services/api/api-public.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    templateUrl: './document-copy.component.html',
})
export class DocumentCopyComponent implements OnInit {
    public accessKey: any;

    public isLoading: boolean;

    public approved: boolean;
    public error: any;

    constructor(
        private _route: ActivatedRoute,
        private apiPublicService: ApiPublicService
    ) {

    }

    public ngOnInit(): void {
        this.accessKey = this._route.snapshot.params['accessKey'];
        if (this.accessKey) {
            this.documentCopyApprove();
        }
    }

    /**
     * Will try to approve the document copy
     */
    public documentCopyApprove(): void {
        this.isLoading = true;
        this.apiPublicService.documentCopyApprove(this.accessKey).subscribe((result) => {
            this.approved = true;
            this.isLoading = false;
        }, (error: HttpErrorResponse) => {
            if (error.status === 400) {
                this.error = error.error;
            }

            this.approved = false;
            this.isLoading = false;
        });
    }
}
