import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { CasePersonCreateResource } from '../../../../models/resources/case-person/case-person-create.resource';
import { CasePersonUpdateResource } from '../../../../models/resources/case-person/case-person-update.resource';
import { CaseResource } from '../../../../models/resources/case/case.resource';
import { PersonQueryResource } from '../../../../models/resources/person/person-query.resource';
import { PersonResource } from '../../../../models/resources/person/person.resource';
import { QueryResultResource } from '../../../../models/resources/query-result.resource';
import { ApiDocumentTypeService } from '../../../../services/api/api-document-type.service';
import { ApiPersonService } from '../../../../services/api/api-person.service';
import { EnumUtils } from './../../../../extentions/enum-utils';
import { CasePersonResource } from './../../../../models/resources/case-person/case-person.resource';
import { CaseCompanyCategoryEnum } from './../../../../models/resources/case/case-company-category.enum';
import { CaseCreateResource } from './../../../../models/resources/case/case-create.resource';
import { CaseDivisionEnum } from './../../../../models/resources/case/case-division.enum';
import { CaseTypeEnum } from './../../../../models/resources/case/case-type.enum';
import { CaseUpdateResource } from './../../../../models/resources/case/case-update.resource';
import { DocumentTypeQueryResource } from './../../../../models/resources/document-type/document-type-query.resource';
import { DocumentTypeResource } from './../../../../models/resources/document-type/document-type.resource';
import { PersonCreateResource } from './../../../../models/resources/person/person-create.resource';
import { ApiCasePersonService } from './../../../../services/api/api-case-person.service';
import { ApiCaseService } from './../../../../services/api/api-case.service';
import { APIUtils } from './../../../../utils/api-utils.class';
import { LanguageEnum } from '../../../../models/resources/language.enum';
import { PersonUpdateResource } from '../../../../models/resources/person/person-update.resource';

@Component({
    templateUrl: './admin-case.component.html'
})
export class AdminCaseComponent implements OnInit {
    // Fields
    public isLoading: boolean;

    public itemId: any;
    public item: CaseResource;

    public languages: any;
    public caseTypes: any;
    public companyCategories: any;
    public divisions: any;

    public documentTypesQueryResult: QueryResultResource<DocumentTypeResource>;

    public personsQueryResult: QueryResultResource<PersonResource>;

    public newPerson: PersonResource;
    public newCasePerson: CasePersonResource;

    public personSeleted: PersonResource;

    private modelError: Array<any> = new Array<any>();

    constructor(
        public _location: Location,
        private _route: ActivatedRoute,
        private _router: Router,
        private toastrService: ToastrService,
        private translate: TranslateService,
        private apiCaseService: ApiCaseService,
        private apiCasePersonService: ApiCasePersonService,
        private apiDocumentTypeService: ApiDocumentTypeService,
        private apiPersonService: ApiPersonService
    ) {
        this.newPerson = new PersonResource();
        this.newCasePerson = new CasePersonResource();

        this.languages = EnumUtils.getNamesAndValues<LanguageEnum>(LanguageEnum);
        this.caseTypes = EnumUtils.getNamesAndValues<CaseTypeEnum>(CaseTypeEnum);
        this.companyCategories = EnumUtils.getNamesAndValues<CaseCompanyCategoryEnum>(CaseCompanyCategoryEnum);
        this.divisions = EnumUtils.getNamesAndValues<CaseDivisionEnum>(CaseDivisionEnum);

        this.translate.onLangChange.subscribe(() => {
            this.languages = EnumUtils.getNamesAndValues<LanguageEnum>(LanguageEnum);
            this.caseTypes = EnumUtils.getNamesAndValues<CaseTypeEnum>(CaseTypeEnum);
            this.companyCategories = EnumUtils.getNamesAndValues<CaseCompanyCategoryEnum>(CaseCompanyCategoryEnum);
            this.divisions = EnumUtils.getNamesAndValues<CaseDivisionEnum>(CaseDivisionEnum);

            this.translateLanguage();
            this.translateCaseType();
            this.translateCompanyCategory();
            this.translateDivisions();
        });
    }

    /**
     * Parses Date to propper format
     * @param dateString
     */
    public parseDate(dateString: string): Date {
        this.modelError = new Array<any>();
        if (dateString) {
            const date = moment(dateString, 'DD/MM/YYYY');
            if (!date.isValid()) {
                this.modelError['personBirthdate'] = [];
                this.modelError['personBirthdate'][0] = 'Invalid date format use dd-mm-yyyy';
                return null;
            }
            return date.toDate();
        } else {
            return null;
        }
    }

    /**
     * Angular Events
     */
    public ngOnInit(): void {
        this.itemId = this._route.snapshot.params['caseId'];
        if (this.itemId !== 'new') {
            this.pullCase();
        } else {
            this.item = new CaseResource();
            this.item.id = 0;
        }

        this.pullDocumentTypes();

        this.translateLanguage();
        this.translateCaseType();
        this.translateCompanyCategory();
        this.translateDivisions();
    }


    /**
     * Will translate the language to HumanRedable
     */
    private translateLanguage(): void {
        // Translating caseLanguageEnum to translated text
        const languageEnumTranslations = [];
        this.languages.forEach(item => {
            languageEnumTranslations.push('caselanguageenum.' + item.value);
        });
        this.translate.get(languageEnumTranslations).subscribe(translations => {
            this.languages.forEach(item => {
                item.name = translations['caselanguageenum.' + item.value];
            });
        });
    }

    /**
     * Will translate the CaseTypes to HumanRedable
     */
    private translateCaseType(): void {
        // Translating caseType enum to translated text
        const caseTypeTranslations = [];
        this.caseTypes.forEach(item => {
            caseTypeTranslations.push('casetypeenum.' + item.value);
        });
        this.translate.get(caseTypeTranslations).subscribe(translations => {
            this.caseTypes.forEach(caseType => {
                caseType.name = translations['casetypeenum.' + caseType.value];
            });
        });
    }

    /**
     * Will translate the CompanyCategory to HumanRedable
     */
    private translateCompanyCategory(): void {
        // Translating companyCategories to translated text
        const languageEnumTranslations = [];
        this.companyCategories.forEach(item => {
            languageEnumTranslations.push('casecompanycategoryenum.' + item.value);
        });
        this.translate.get(languageEnumTranslations).subscribe(translations => {
            this.companyCategories.forEach(item => {
                item.name = translations['casecompanycategoryenum.' + item.value];
            });
        });
    }

    /**
     * Will translate the Divisions to HumanRedable
     */
    private translateDivisions(): void {
        // Translating divisions to translated text
        const languageEnumTranslations = [];
        this.divisions.forEach(item => {
            languageEnumTranslations.push('casedivisionenum.' + item.value);
        });
        this.translate.get(languageEnumTranslations).subscribe(translations => {
            this.divisions.forEach(item => {
                item.name = translations['casedivisionenum.' + item.value];
            });
        });
    }

    /**
     * Pull Case
     */
    private pullCase(): void {
        this.isLoading = true;
        this.translate.get([
            'toasty.error.pulltitle',
            'toasty.error.pullmsg',
        ]).subscribe(translations => {
            this.apiCaseService.get(this.itemId).subscribe((item) => {
                this.item = item;
                this.pullPersons();
                this.isLoading = false;
            }, (error: HttpErrorResponse) => {
                this.isLoading = false;
                APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to get Case!');
            });
        });
    }

    /**
     * Pull DocumentTypes
     */
    private pullDocumentTypes(): void {
        const query = new DocumentTypeQueryResource();
        query.pageSize = -1;

        this.apiDocumentTypeService.getAll(query).subscribe((result) => {
            this.documentTypesQueryResult = result;
            this.isLoading = false;
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to get DocumentTypes!');
        });
    }

    /**
     * Pulls Persons from API
     */
    private pullPersons(): void {
        const query = new PersonQueryResource();
        query.pageSize = -1;
        this.apiPersonService.getAll(query).subscribe((result) => {
            this.personsQueryResult = result;
            this.item.persons.forEach((person) => {
                const index = this.personsQueryResult.items.findIndex(x => x.id === person.personId);
                if (index > -1) {
                    this.personsQueryResult.items.splice(index, 1);
                }
            });
        }, (error: HttpErrorResponse) => {
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to get Persons!');
        });
    }

    /**
     * Calles the create Case API
     */
    public create(item: CaseResource): void {
        this.translate.get([
            'toasty.error.createdtitle',
            'toasty.error.createdmsg',
            'toasty.case.createdtitle',
            'toasty.case.createdmsg'
        ]).subscribe(translations => {
            this.isLoading = true;
            const createResouce = new CaseCreateResource(item);
            this.modelError = new Array<any>();
            this.apiCaseService.create(createResouce).subscribe((itemResult) => {
                this.item = itemResult;
                this.pullPersons();
                this.toastrService.success(translations['toasty.case.createdmsg'], translations['toasty.case.createdtitle']);
                this._router.navigate(['admin/cases/', this.item.id]);
                this.isLoading = false;
            }, (error: HttpErrorResponse) => {
                this.isLoading = false;
                APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to create CasePerson!');
            });
        });
    }

    /**
     * Calles the update Case API
     */
    public update(item: CaseResource): void {
        this.translate.get([
            'toasty.error.updatedtitle',
            'toasty.error.updatedmsg',
            'toasty.case.updatedtitle',
            'toasty.case.updatedmsg'
        ]).subscribe(translations => {
            const updateResource = new CaseUpdateResource(item);
            this.modelError = new Array<any>();

            const calls = [];
            this.item.persons.forEach((casePerson) => {
                // Creating CasePerson Calls
                const updateCasePersonResource = new CasePersonUpdateResource(casePerson);
                const casePersonCall = this.apiCasePersonService.update(casePerson.id, updateCasePersonResource);
                calls.push(casePersonCall);

                // Creating Person Calls
                const updatePerson = new PersonUpdateResource(casePerson.person);
                const personCall = this.apiPersonService.update(casePerson.person.id, updatePerson);
                calls.push(personCall);
            });

            const forkJoinCasePerson = forkJoin(calls);
            forkJoinCasePerson.subscribe((result) => {
                this.apiCaseService.update(item.id, updateResource).subscribe((itemResult) => {
                    this.item = itemResult;
                    this.toastrService.success(translations['toasty.case.updatedmsg'], translations['toasty.case.updatedtitle']);
                }, (error: HttpErrorResponse) => {
                    APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to update Case!');
                });
            }, (error: HttpErrorResponse) => {
                APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to update Case!');
            });
        });
    }

    /**
     * Will add a person to the case
     * @param person
     */
    public createCasePerson(person: PersonResource): void {
        this.personSeleted = null;

        const casePerson = new CasePersonResource();
        casePerson.caseId = this.item.id;
        casePerson.personId = person.id;

        const createResource = new CasePersonCreateResource(casePerson);

        this.apiCasePersonService.create(createResource).subscribe((itemResult) => {
            this.toastrService.success('', 'CasePerson Created');
            this.item.persons.push(itemResult);
            this.pullPersons();
        }, (error: HttpErrorResponse) => {
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to create CasePerson!');
        });
    }

    /**
     * Will update the CasePerson
     * @param person
     */
    public updateCasePerson(casePerson: CasePersonResource): void {
        const updateResource = new CasePersonUpdateResource(casePerson);
        this.apiCasePersonService.update(casePerson.id, updateResource).subscribe((itemResult) => {
            this.toastrService.success('', 'CasePerson Updated');
            casePerson = itemResult;
        }, (error: HttpErrorResponse) => {
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to update CasePerson!');
        });
    }

    /**
     * Will delete the CasePerson
     * @param person
     */
    public deleteCasePerson(casePerson: CasePersonResource): void {
        const name = `${casePerson.person.firstName} ${casePerson.person.lastName}`;
        const result = confirm(`Are you sure you want to delete ${name} - ${casePerson.person.email}`);
        if (!result) {
            return;
        }

        this.apiCasePersonService.delete(casePerson.id).subscribe((itemResult) => {
            this.toastrService.success('', 'CasePerson removed');
            const index = this.item.persons.indexOf(casePerson);
            if (index > -1) {
                this.item.persons.splice(index, 1);
            }
            this.pullCase();
        }, (error: HttpErrorResponse) => {
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to delete CasePerson!');
        });
    }

    /**
     * Will resend an upload link to the CasePerson
     * @param casePerson CasePerson to resend to
     */
    public resendCasePerson(casePerson: CasePersonResource): void {
        this.apiCasePersonService.resendUploadLink(casePerson.id).subscribe((itemResult) => {
            this.toastrService.success('', 'Email resend');
            casePerson.isUploadEmailSend = itemResult.isUploadEmailSend;
        }, (error: HttpErrorResponse) => {
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to resend CasePerson!');
        });
    }

    /**
     * Checks if a string is null or empty
     * @param value string to check
     */
    public isNullOrEmpty(value: string): boolean {
        return (!value || value === undefined || value === '' || value.length === 0);
    }

    /**
     * Will Create a person and a CasePerson
     */
    public createPerson(): void {
        const personCreateResource = new PersonCreateResource(this.newPerson);
        this.apiPersonService.create(personCreateResource).subscribe((person) => {
            const casePersonCreateResource = new CasePersonCreateResource(this.newCasePerson);
            casePersonCreateResource.caseId = this.item.id;
            casePersonCreateResource.personId = person.id;

            this.apiCasePersonService.create(casePersonCreateResource).subscribe((casePerson) => {
                this.newPerson = new PersonResource();
                this.newCasePerson = new CasePersonResource();

                this.toastrService.success('', 'CasePerson Created');
                this.item.persons.push(casePerson);
                this.pullPersons();
            }, (error: HttpErrorResponse) => {
                APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to create CasePerson!');
            });
        }, (error: HttpErrorResponse) => {
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to create Person!');
        });
    }
}
