import { CaseCompanyCategoryEnum } from './case-company-category.enum';
import { CaseDivisionEnum } from './case-division.enum';
import { CaseTypeEnum } from './case-type.enum';
import { CaseResource } from './case.resource';

export class CaseCreateResource {
    public caseNumber: string;
    public caseName: string;

    public type: CaseTypeEnum;
    public division: CaseDivisionEnum;
    
    public active: boolean;

    public companyName: string;
    public companyCVRNumber: string;
    public companyAddress: string;
    public companyZipcode: string;
    public companyCity: string;
    public companyCountry: string;
    public companyCategory: CaseCompanyCategoryEnum;

    public message: string;

    constructor(resource: CaseResource) {
        this.caseNumber = resource.caseNumber;
        this.caseName = resource.caseName;

        this.division = resource.division;

        this.type = resource.type;

        this.active = resource.active;

        this.companyName = resource.companyName;
        this.companyCVRNumber = resource.companyCVRNumber;
        this.companyAddress = resource.companyAddress;
        this.companyZipcode = resource.companyZipcode;
        this.companyCity = resource.companyCity;
        this.companyCountry = resource.companyCountry;
        this.companyCategory = resource.companyCategory;

        this.message = resource.message;
    }
}
