import { CaseResource } from '../case/case.resource';
import { KeyValuePairResource } from '../key-value-pair.resource';
import { PersonResource } from '../person/person.resource';
import { ResourceBase } from '../resource-base.resource';
import { DocumentResource } from '../document/document.resource';

export class CasePersonResource extends ResourceBase {
    public accessKey: string;

    public isUploadEmailSend: boolean;

    public case: CaseResource;
    public caseId: number;

    public person: PersonResource;
    public personId: number;

    public documentTypes: Array<KeyValuePairResource>;
    public documentTypeIds: Array<number>;

    // Client Only
    public documents: Array<DocumentResource>;

    constructor() {
        super();
        this.documentTypes = new Array<KeyValuePairResource>();
        this.documentTypeIds = new Array<number>();

        this.documents = new Array<DocumentResource>();
    }
}

