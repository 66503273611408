import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { QueryResultResource } from '../../../../models/resources/query-result.resource';
import { DocumentTypeCreateResource } from './../../../../models/resources/document-type/document-type-create.resource';
import { DocumentTypeQueryResource } from './../../../../models/resources/document-type/document-type-query.resource';
import { DocumentTypeUpdateResource } from './../../../../models/resources/document-type/document-type-update.resource';
import { DocumentTypeResource } from './../../../../models/resources/document-type/document-type.resource';
import { ApiDocumentTypeService } from './../../../../services/api/api-document-type.service';

@Component({
    templateUrl: './admin-settings-document-types.component.html',
})
export class AdminSettingsDocumentTypesComponent implements OnInit {
    // Fields
    public query: DocumentTypeQueryResource = new DocumentTypeQueryResource();
    public queryResult: QueryResultResource<DocumentTypeResource>;
    public queryCount: number;

    public itemNew: DocumentTypeResource = new DocumentTypeResource();

    constructor(
        private toastrService: ToastrService,
        private apiDocumentTypeService: ApiDocumentTypeService
    ) {
        this.query = new DocumentTypeQueryResource();
    }

    public ngOnInit(): void {
        this.pullDocumentTypes();
    }

    /*
     * API Calls
     */
    public pullDocumentTypes(): void {
        this.apiDocumentTypeService.getAll(this.query).subscribe(queryResult => {
            this.queryResult = queryResult;
            this.queryCount = queryResult.count;
            if (this.queryResult.items == null) {
                this.queryResult.items = new Array<DocumentTypeResource>();
            }
        }, (error: HttpErrorResponse) => {
            this.toastrService.error(error.message, 'Failed to pull Document Types!');
        });
    }

    /**
     * Creates a document type
     * @param item The item to be create
     */
    public createDocumentType(item: DocumentTypeResource): void {
        const createResource = new DocumentTypeCreateResource(item);
        this.apiDocumentTypeService.create(createResource).subscribe((itemResult) => {
            this.itemNew = new DocumentTypeResource();
            this.toastrService.success('', 'Document Type Created');
            this.pullDocumentTypes();
        }, (error: HttpErrorResponse) => {
            this.toastrService.error(error.message, 'Failed to create Document Type!');
        });
    }

    /**
     * Updates a document type
     * @param item The item to be updates
     */
    public updateDocumentType(item: DocumentTypeResource): void {
        const updateResource = new DocumentTypeUpdateResource(item);
        this.apiDocumentTypeService.update(item.id, updateResource).subscribe((itemResult) => {
            const index = this.queryResult.items.findIndex(i => i.id === item.id);
            this.queryResult.items[index] = item;

            this.toastrService.success('', 'Document Type Updated');
        }, (error: HttpErrorResponse) => {
            this.toastrService.error(error.message, 'Failed to update Document Type!');
        });
    }
}
