import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { CaseQueryResource } from '../../../../models/resources/case/case-query.resource';
import { CaseResource } from '../../../../models/resources/case/case.resource';
import { DocumentCopyCreateResource } from '../../../../models/resources/document-copy/document-copy-create.resource';
import { DocumentResource } from '../../../../models/resources/document/document.resource';
import { PersonResource } from '../../../../models/resources/person/person.resource';
import { QueryResultResource } from '../../../../models/resources/query-result.resource';
import { ApiCaseService } from '../../../../services/api/api-case.service';
import { ApiDocumentCopyService } from '../../../../services/api/api-document-copy.service';
import { ApiPersonService } from '../../../../services/api/api-person.service';
import { APIUtils } from '../../../../utils/api-utils.class';

@Component({
    templateUrl: './admin-person-copy.component.html',
})
export class AdminPersonCopyComponent implements OnInit {
    // Properties
    public isLoading: boolean;

    public itemId;
    public item: PersonResource;

    public caseQueryResult: QueryResultResource<CaseResource>;
    public caseIdSelected: number;

    public documents: Array<DocumentResource>;
    public documentsSelected: Array<number>;

    constructor(
        public _location: Location,
        private _route: ActivatedRoute,
        private _router: Router,
        private toastrService: ToastrService,
        private apiCaseService: ApiCaseService,
        private apiPersonService: ApiPersonService,
        private apiDocumentCopyService: ApiDocumentCopyService
    ) {
        this.documents = new Array<DocumentResource>();
    }

    public ngOnInit(): void {
        this.itemId = this._route.snapshot.params['itemId'];
        this.pullPerson();
        this.pullCases();
    }
    /**
     * Pulls the current person
     */
    public pullPerson(): void {
        this.isLoading = true;
        this.apiPersonService.get(this.itemId).subscribe((item) => {
            this.item = item;

            this.item.casePersons.forEach((casePerson) => {
                casePerson.documents.forEach((document)  => {
                    this.documents.push(document);
                });
            });

            this.isLoading = false;
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to get Person!');
        });
    }

    /**
     * Pulls All cases
     */
    public pullCases(): void {
        const query = new CaseQueryResource();
        query.pageSize = -1;
        query.division = -1;
        query.sortBy = 'caseNumber';
        query.isSortAscending = true;

        //query.casePersonId = this.itemId;

        this.apiCaseService.getAll(query).subscribe(queryResult => {
            this.caseQueryResult = queryResult;
        }, (error: HttpErrorResponse) => {
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to get Cases!');
        });
    }

    /**
     * Creates a DocumentCopy
     */
    public createDocumentCopy(): void {
        if (!this.caseIdSelected) {
            this.toastrService.warning('No case selected', 'Create Copy');
            return;
        }

        const documentsSelected = this.documents.filter(x => x.selected).map(x => x.id);
        if (documentsSelected.length <= 0) {
            this.toastrService.warning('No documents has been selected', 'Create Copy');
            return;
        }

        const createResource = new DocumentCopyCreateResource();
        createResource.caseId = this.caseIdSelected;
        createResource.personId = this.item.id;
        createResource.documentIds = documentsSelected;

        this.apiDocumentCopyService.create(createResource).subscribe((result) => {
            this.toastrService.success('', 'DocumentCopy Created');
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to create DocumentCopy!');
        });
    }
}
