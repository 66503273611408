import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { APIUtils } from '../../../utils/api-utils.class';

import { PersonQueryResource } from '../../../models/resources/person/person-query.resource';
import { PersonResource } from '../../../models/resources/person/person.resource';
import { QueryResultResource } from '../../../models/resources/query-result.resource';
import { ApiPersonService } from '../../../services/api/api-person.service';

@Component({
    templateUrl: './admin-persons.component.html',
})
export class AdminPersonsComponent implements OnInit {
    // Properties
    public isLoading: boolean;

    public searchText: string;
    public query: PersonQueryResource;
    public queryResult: QueryResultResource<PersonResource>;

    constructor(
        public _location: Location,
        private _route: ActivatedRoute,
        private _router: Router,
        private apiPersonService: ApiPersonService,
        private toastrService: ToastrService,
        private translate: TranslateService
    ) { }

    public ngOnInit(): void {
        this.query = new PersonQueryResource();
        this.query.page = 1;
        this.query.pageSize = 15;
        this.query.sortBy = 'firstName';
        this.query.isSortAscending = true;

        //this.pullPersons();

        this._route.queryParams.subscribe(params => {
            if(params.searchText) {
                this.searchText = params.searchText;
            } else {
                this.searchText = "";
            }
            
            if(params.page) {
                this.query.page = Number(params.page);
            } else {
                this.query.page = 1;
            }

            this.query.name = this.searchText;
            this.query.email = this.searchText;

            this.pullPersons();
        });
    }

    /**
     * Pulls Persons
     */
    private pullPersons(): void {
        this.isLoading = true;
        this.apiPersonService.getAll(this.query).subscribe(queryResult => {
            this.queryResult = queryResult;
            this.isLoading = false;
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;

            this.toastrService.error(error.message, 'Failed to pull Persons!');
        });
    }

    /**
     * Updates the query and repulls the objects
     */
    public search(): void {
        this.query.page = 1;
        this.query.name = this.searchText;
        this.query.email = this.searchText;

        this._router.navigate([], {
            queryParams: {
                searchText: this.searchText,
                page: this.query.page
            }, 
            queryParamsHandling: 'merge' 
        });

        this.pullPersons();
    }

    /**
     * Will apply a sortBy filter
     */
    public sortBy(sortBy: string): void {
        if(this.query.sortBy === sortBy) {
            this.query.isSortAscending = !this.query.isSortAscending;
        } else {
            this.query.isSortAscending = false;
            this.query.sortBy = sortBy;
        }
        this.pullPersons();
    }

    /**
     * Changes the current page
     * @param page
     */
    public pageChange(page): void {
        this.query.page = page;

        this._router.navigate([], {
            queryParams: {
                page: this.query.page,
            }, 
            queryParamsHandling: 'merge' 
        });

        this.pullPersons();
    }

    /**
     * Will try to delete the case
     * @param item
     */
    public delete(item: PersonResource): void {
        this.translate.get([
            'toasty.error.deletedtitle',
            'toasty.error.deletedmsg',
            'toasty.person.deletedtitle',
            'toasty.person.deletedmsg',
        ]).subscribe(translations => {
            this.apiPersonService.delete(item.id).subscribe((result) => {
                this.toastrService.success(translations['toasty.person.deletedmsg'], translations['toasty.person.deletedtitle']);
                this.pullPersons();
            }, (error: HttpErrorResponse) => {
                APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to delete Person!');
                //this.toastrService.error(error.message, 'Failed to delete Person!');
                this.pullPersons();
            });
        });
    }
}
