import { Component, Input, OnInit } from '@angular/core';

import { CaseStatusEnum } from '../../../../models/resources/case/case-status.enum';

@Component({
    selector: 'case-status-badge',
    template: `
        <span class="badge badge-warning" *ngIf="status == 0">{{ 'case-status.status0' | translate }}</span>
        <span class="badge badge-warning" *ngIf="status == 1">{{ 'case-status.status1' | translate }}</span>
        <span class="badge badge-success" *ngIf="status == 2">{{ 'case-status.status2' | translate }}</span>
        <span class="badge badge-danger" *ngIf="status == 3">{{ 'case-status.status3' | translate }}</span>
        <span class="badge badge-warning" *ngIf="status == 4">{{ 'case-status.status4' | translate }}</span>
        <span class="badge badge-danger" *ngIf="status == 5">{{ 'case-status.status5' | translate }}</span>
    `,
    styles: [``]
})
export class CaseStatusBadgeComponent {
    // Input
    @Input() status: CaseStatusEnum;

    constructor() { }
}
