import { CasePersonResource } from './case-person.resource';

export class CasePersonCreateResource {
    // Relations
    public caseId: number;
    public personId: number;

    public documentTypeIds: Array<number>;

    constructor(resource: CasePersonResource) {
        this.caseId = resource.caseId;
        this.personId = resource.personId;

        this.documentTypeIds = resource.documentTypeIds;
    }
}
