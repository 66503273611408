import { CasePersonResource } from './case-person.resource';

export class CasePersonUpdateResource {
    // Relations
    public documentTypeIds: Array<number>;

    constructor(resource: CasePersonResource) {
        this.documentTypeIds = resource.documentTypeIds;
    }
}
