import { JSEncrypt } from 'jsencrypt';

export class RSAUtils {
    /**
     * This is used to encrypt a text used in the AES encryption
     * @param publicKey
     * @param text
     */
    public static EncryptText(publicKey: string, text: string) {
        // Creating JSEncrypt
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(publicKey);

        // Encrypt
        const textEncrypted = jsEncrypt.encrypt(text);
        return textEncrypted;
    }

    /**
     * This is used to decrypt a text used in the AES decryption
     * @param privateKey
     * @param textEncrypted
     */
    public static DecryptText(privateKey: string, textEncrypted: string) {
        // Creating JSEncrypt
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPrivateKey(privateKey);

        // Encrypt
        const text = jsEncrypt.decrypt(textEncrypted);
        return text;
    }

    /**
     * Generates a new keypair
     */
    public static async GenerateKeyPair(): Promise<JSEncrypt> {
        return new Promise((resolve, reject) => {
            const jsEncrypt = new JSEncrypt({
                default_key_size: 2048
            });

            jsEncrypt.getKey(() => resolve(jsEncrypt));
        });
    }
}
