
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { PersonCreateResource } from '../../models/resources/person/person-create.resource';
import { PersonQueryResource } from '../../models/resources/person/person-query.resource';
import { PersonUpdateResource } from '../../models/resources/person/person-update.resource';
import { PersonResource } from '../../models/resources/person/person.resource';
import { QueryResultResource } from '../../models/resources/query-result.resource';
import { APIUtils } from '../../utils/api-utils.class';

@Injectable()
export class ApiPersonService {
    private readonly endpoint = environment.apiUrl + 'persons/';

    constructor(
        private _http: HttpClient,
    ) {}

    private mapObject(resource: PersonResource): PersonResource {
        resource.createdAt = new Date(resource.createdAt);
        resource.modifiedAt = new Date(resource.modifiedAt);

        // Client Only
        if(resource.alternativeEmail) {
            resource.displayName = `${resource.firstName} ${resource.lastName} ${resource.email} (${resource.alternativeEmail})`
        } else {
            resource.displayName = `${resource.firstName} ${resource.lastName} ${resource.email}`
        }
        return resource;
    }

    public get(itemId: number): Observable<PersonResource> {
        return this._http.get<PersonResource>(`${this.endpoint}${itemId}`).pipe(map((result) => this.mapObject(result)));
    }

    public getAll(queryResource: PersonQueryResource): Observable<QueryResultResource<PersonResource>> {
        const query = `${this.endpoint}${APIUtils.objectToQueryString(queryResource)}`;
        return this._http.get<QueryResultResource<PersonResource>>(query).pipe(map((result) => {
            result.items.forEach((item) => item = this.mapObject(item));
            return result;
        }));
    }

    public create(createResource: PersonCreateResource): Observable<PersonResource> {
        return this._http.post<PersonResource>(`${this.endpoint}`, createResource).pipe(map((result) => this.mapObject(result)));
    }

    public update(itemId: number, updateResource: PersonUpdateResource): Observable<PersonResource> {
        return this._http.put<PersonResource>(`${this.endpoint}${itemId}`, updateResource).pipe(map((result) => this.mapObject(result)));
    }

    public delete(itemId: number): Observable<string> {
        return this._http.delete(`${this.endpoint}${itemId}`, { responseType: 'text' }).pipe(map((result) => result));
    }
}
