import { RouterModule, Routes } from '@angular/router';

import { AdminAuthenticationGuard } from '../../guards/admin-authentication.guard';
import { AdminCaseViewComponent } from './admin-cases/admin-case-view/admin-case-view.component';
import { AdminCaseComponent } from './admin-cases/admin-case/admin-case.component';
import { AdminCasesComponent } from './admin-cases/admin-cases.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminComponent } from './admin.component';
import { AdminSettingsDocumentTypesComponent } from './admin-settings/admin-settings-document-types/admin-settings-document-types.component';
import { AdminPersonsComponent } from './admin-persons/admin-persons.component';
import { AdminPersonComponent } from './admin-persons/admin-person/admin-person.component';
import { AdminPersonCopyComponent } from './admin-persons/admin-person-copy/admin-person-copy.component';
import { AdminPersonDocumentsComponent } from './admin-persons/admin-person-documents/admin-person-documents.component';

const AdminRoutes: Routes = [{
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminAuthenticationGuard],
    children: [
        { path: '', component: AdminHomeComponent },
        {
            // Case Routes
            path: 'cases',
            children: [
                { path: '', component: AdminCasesComponent },
                { path: ':caseId', component: AdminCaseComponent },
                { path: ':caseId/view', component: AdminCaseViewComponent }
            ]
        },
        {
            // Persons Routes
            path: 'persons',
            children: [
                { path: '', component: AdminPersonsComponent },
                { path: ':itemId', component: AdminPersonComponent },
                { path: ':itemId/copy', component: AdminPersonCopyComponent },
                { path: ':itemId/documents', component: AdminPersonDocumentsComponent },
            ]
        },
        {
            // Setting Routes
            path: 'settings',
            children: [
                { path: 'documenttypes', component: AdminSettingsDocumentTypesComponent },
            ]
        }
    ]
}];
export const AdminRouting = RouterModule.forChild(AdminRoutes);
