import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { EnumUtils } from '../../../../extentions/enum-utils';
import { LanguageEnum } from '../../../../models/resources/language.enum';
import { PersonCreateResource } from '../../../../models/resources/person/person-create.resource';
import { PersonUpdateResource } from '../../../../models/resources/person/person-update.resource';
import { PersonResource } from '../../../../models/resources/person/person.resource';
import { ApiPersonService } from '../../../../services/api/api-person.service';
import { APIUtils } from '../../../../utils/api-utils.class';

@Component({
    templateUrl: './admin-person.component.html',
})
export class AdminPersonComponent implements OnInit {
    // Fields
    private languages: any;

    // Bindable Properties
    public isLoading: boolean;

    public itemId;
    public item: PersonResource;

    constructor(
        public _location: Location,
        private _route: ActivatedRoute,
        private _router: Router,
        private translate: TranslateService,
        private toastrService: ToastrService,
        private apiPersonService: ApiPersonService
    ) {
        this.languages = EnumUtils.getNamesAndValues<LanguageEnum>(LanguageEnum);

        this.translate.onLangChange.subscribe(() => {
            this.languages = EnumUtils.getNamesAndValues<LanguageEnum>(LanguageEnum);

            this.translateLanguage();
        });
    }

    public ngOnInit(): void {
        this.itemId = this._route.snapshot.params['itemId'];
        if (this.itemId !== 'new') {
            this.pullPerson();
        } else {
            this.item = new PersonResource();
            this.item.id = 0;
        }

        this.translateLanguage();
    }

    public parseDate(dateString: string): Date {
        if (dateString) {
            const date = moment(dateString, 'DD/MM/YYYY');
            if (!date.isValid()) {
                // this.modelError['personBirthdate'] = [];
                // this.modelError['personBirthdate'][0] = 'Invalid date format use dd-mm-yyyy';
                return null;
            }

            return date.toDate();
        } else {
            return null;
        }
    }

    /**
     * Pulls the current person
     */
    public pullPerson(): void {
        this.isLoading = true;
        this.apiPersonService.get(this.itemId).subscribe((item) => {
            this.item = item;
            this.isLoading = false;
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.toastrService.error(error.message, 'Failed to get Person!');
        });
    }

    /**
     * Calles the create Case API
     */
    public create(item: PersonResource): void {
        const createResouce = new PersonCreateResource(item);

        this.apiPersonService.create(createResouce).subscribe((itemResult) => {
            this.item = itemResult;
            this.toastrService.success('', 'Person Created');
            this._router.navigate(['/admin/persons/', itemResult.id]);
        }, (error) => {
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to Create Person!');
        });
    }

    /**
     * Calles the update Case API
     */
    public update(item: PersonResource): void {
        const updateResource = new PersonUpdateResource(item);
        this.apiPersonService.update(item.id, updateResource).subscribe((itemResult) => {
            this.item = item;
            this.toastrService.success('', 'Person Updated');
        }, (error: HttpErrorResponse) => {
            APIUtils.parseHttpErrorResponse(this.toastrService, error, 'Failed to update Person!');
        });
    }

    /**
     * Will translate the language to HumanRedable
     */
    private translateLanguage(): void {
        // Translating caseLanguageEnum to translated text
        const languageEnumTranslations = [];
        this.languages.forEach(item => {
            languageEnumTranslations.push('caselanguageenum.' + item.value);
        });
        this.translate.get(languageEnumTranslations).subscribe(translations => {
            this.languages.forEach(item => {
                item.name = translations['caselanguageenum.' + item.value];
            });
        });
    }
}
