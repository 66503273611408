import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
	selector: 'pagination',
	templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnChanges {
    
    @Input('itemCount') itemCount;
	@Input('pageSize') pageSize = 10;
	@Input('pagingRange') pagingRange = 5;
	@Output('pageChanged') pageChanged = new EventEmitter();

	private pages: any[];
    private pageCount: number;
    private currentPage: number;
    
    @Input()
    set page(val: any) {
        this.currentPage = val;
    }

    public ngOnChanges(): void {
		this.pageCount = Math.ceil(this.itemCount / this.pageSize);
		this.calcPages(false);
    }

	private calcPages(emit:boolean = true) {
		this.pages = [];

        const halfWay = Math.ceil(this.pagingRange / 2);
        const isStart = this.currentPage <= halfWay;
        const isEnd = this.pageCount - halfWay < this.currentPage;
        const isMiddle = !isStart && !isEnd;

		let ellipsesNeeded = this.pagingRange < this.pageCount;
        let i = 1;

        while (i <= this.pageCount && i <= this.pagingRange) {
            let label;
            let pageNumber = this.calculatePageNumber(i, this.pageCount);
            let openingEllipsesNeeded = (i === 2 && (isMiddle || isEnd));
            let closingEllipsesNeeded = (i === this.pagingRange - 1 && (isMiddle || isStart));
            if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
                label = '...';
            } else {
                label = pageNumber;
            }
            this.pages.push(pageNumber);
            i++;
		}

        if(emit)
            this.pageChanged.emit(this.currentPage);
	}

	private calculatePageNumber(i: number, totalPages: number) {
        let halfWay = Math.ceil(this.pagingRange / 2);
        if (i === this.pagingRange) {
            return totalPages;
        } else if (i === 1) {
            return i;
        } else if (this.pagingRange < totalPages) {
            if (totalPages - halfWay < this.currentPage) {
                return totalPages - this.pagingRange + i;
            } else if (halfWay < this.currentPage) {
                return this.currentPage - halfWay + i;
            } else {
                return i;
            }
        } else {
            return i;
        }
    }

	public changePage(page) {
		this.currentPage = page; 
		this.calcPages();
	}

	public previous() {
		if (this.currentPage == 1)
			return;

		this.currentPage--;
		this.calcPages();
	}

	public next() {
		if (this.currentPage == this.pageCount)
			return; 
		
		this.currentPage++;
		this.calcPages();
	}
}
