import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';

import { PublicDocumentCreateResource } from '../../models/resources/public/public-document-create.resource';
import { FileUtils } from '../../utils/file-utils.class';
import { RSAUtils } from '../../utils/rsa-utils.class';
import { PublicCasePersonResource } from './../../models/resources/public/public-case-person.resource';
import { ApiApiService } from './../../services/api/api-api.service';
import { ApiPublicService } from './../../services/api/api-public.service';
import { AESCryptUtils } from './../../utils/aes-crypt-utils.class';
import { Base64Utils } from './../../utils/base64-utils.class';

@Component({
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
    // Fields
    public fileSizeLimit = 10; // In MB
    public fileAllowedTypes = ['png', 'jpg', 'bmp', 'txt', 'pdf'];

    public accessKey: string;

    // Fields - Case
    public casePerson: PublicCasePersonResource;
    public isLoading: boolean;

    // Fields - Documents
    public documents: Array<PublicDocumentCreateResource>;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private apiApiService: ApiApiService,
        private apiPublicService: ApiPublicService
    ) {
        this.documents = new Array<PublicDocumentCreateResource>();
    }

    public ngOnInit(): void {
        this.accessKey = this._route.snapshot.params['accessKey'];
        if (this.accessKey) {
            this.pullCasePerson();
        }
    }

    /**
     * Getting Case
     */
    private pullCasePerson(): void {
        this.isLoading = true;
        this.apiPublicService.getCasePersonByAccessKey(this.accessKey).subscribe((itemResult) => {
            this.casePerson = itemResult;

            this.casePerson.documentTypes.forEach(docuemntType => {
                const document = new PublicDocumentCreateResource();
                document.documentTypeId = docuemntType.id;
                document.documentTypeName = docuemntType.name;
                document.uploaded = (this.casePerson.documentTypeIdsUploaded.indexOf(docuemntType.id) > -1);
                document.uploading = false;
                this.documents.push(document);
            });

            this.isLoading = false;
        }, (error) => {
            // TODO: Error Handle
            this.isLoading = false;
        });
    }

    /**
     * document - Methods
     */
    public browseFile(createResource: PublicDocumentCreateResource) {
        const fileInput = document.getElementById('browsefile_' + createResource.documentTypeId);
        fileInput.click();
    }

    public fileBrowserChanged(createResource: PublicDocumentCreateResource, file: File) {
        // Size limit
        const fileSize = file.size / 1024 / 1024;
        if (fileSize >= this.fileSizeLimit) {
            const errorMessage = `File is to big, ${this.fileSizeLimit} MB is the limit, file is ${Math.round(fileSize * 100) / 100} MB`;
            this.toastrService.error(errorMessage, 'File size is to large!');
            return;
        }

        // File Type
        const fileExtention = file.name.substr(file.name.lastIndexOf('.') + 1).toLocaleLowerCase();
        if (!(this.fileAllowedTypes.indexOf(fileExtention) > -1)) {
            let allowedTypes: string;
            this.fileAllowedTypes.forEach(fileType => allowedTypes +=  ' .' + fileType);

            const errorMessage = `'Wrong file type only ${allowedTypes} are allowed',`;
            this.toastrService.error(errorMessage, 'File is wrong type!');
            return;
        }

        // Encrypting file and uploading
        this.encryptFile(createResource, file);
    }

    /**
     * Encrypts file
     */
    private encryptFile(createResource: PublicDocumentCreateResource, file: File) {
        createResource.uploading = true;
        this.apiApiService.getPublicKey().subscribe(async (publicKey: string) => {
            // Generating / Encrypting Key
            const key = AESCryptUtils.generateKey();
            const keyBase64 = Base64Utils.bufferToBase64(key);
            const keyEncrypted = RSAUtils.EncryptText(publicKey, keyBase64);

            // Generating / Encrypting IV
            const iv = AESCryptUtils.generateIv();
            const ivBase64 = Base64Utils.bufferToBase64(iv);
            const ivEncrypted = RSAUtils.EncryptText(publicKey, ivBase64);

            // Encrypting
            const fileBase64 = await FileUtils.fileToBase64String(file);
            const fileWordArray = CryptoJS.enc.Base64.parse(fileBase64);
            const cipherWordArray = AESCryptUtils.cryptoJSEncrypt(keyBase64, ivBase64, fileWordArray);

            // Creating DocumentCreateModel
            createResource.key = keyEncrypted;
            createResource.iv = ivEncrypted;
            createResource.fileData = cipherWordArray.toString();
            createResource.fileName = file.name;
            createResource.fileExtention = file.name.substr(file.name.lastIndexOf('.') + 1);
            createResource.fileSize = file.size;

            // Uploading
            this.apiPublicService.createDocument(this.accessKey, createResource).subscribe((item) => {
                this.casePerson = item;
                createResource.uploading = false;
                createResource.uploaded = true;
            }, (error: HttpErrorResponse) => {
                createResource.uploading = false;
                createResource.uploaded = false;
                this.toastrService.error(error.message, 'Failed to upload file!');
            });
        }, (error: HttpErrorResponse) => {
            createResource.uploading = false;
            createResource.uploaded = false;
            this.toastrService.error(error.message, 'Failed to public key!');
        });
    }
}
