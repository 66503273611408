
import {map} from 'rxjs/operators';



import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class ApiApiService {
    private readonly endpoint = environment.apiUrl;

    constructor(
        private _http: HttpClient,
    ) {}

    public getVersion(): Observable<string> {
        return this._http.get(`${this.endpoint}version`, { responseType: 'text' }).pipe(map((res) => res));
    }

    public hasAccess(): Observable<string> {
        return this._http.get(`${this.endpoint}hasaccess`, { responseType: 'text' }).pipe(map((res) => res))
    }

    public getPublicKey(): Observable<string> {
        return this._http.get(`${this.endpoint}publickey`, { responseType: 'text' }).pipe(map((res) => res))
    }
}
