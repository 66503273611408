import { WordArray } from 'crypto-js';
import * as CryptoJS from 'crypto-js';

// Addes msCrypto namespace
declare global {
    interface Window { msCrypto: Crypto; }
}

export class AESCryptUtils {
    private static cryptoObject: Crypto = window.crypto || window.msCrypto;

    public static generateKey() {
        const key = new Uint8Array(32);
        this.cryptoObject.getRandomValues(key);
        return key;
    }

    public static generateIv() {
        const iv = new Uint8Array(16);
        this.cryptoObject.getRandomValues(iv);
        return iv;
    }

    public static cryptoEncrypt(key: Uint8Array, iv: Uint8Array, plainArr: ArrayBuffer | Uint8Array): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            this.cryptoObject.subtle.importKey('raw', key, 'aes-gcm', false, ['encrypt']).then((keyResult) => {
                this.cryptoObject.subtle.encrypt({ name: 'aes-gcm', iv: iv }, keyResult, plainArr).then((cipherArr) => {
                    resolve(cipherArr);
                });
            });
        });
    }

    public static cryptoDecrypt(key: Uint8Array, iv: Uint8Array, cipherArr: ArrayBuffer | Uint8Array): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            this.cryptoObject.subtle.importKey('raw', key, 'aes-gcm', false, ['decrypt']).then((keyResult) => {
                this.cryptoObject.subtle.decrypt({ name: 'aes-gcm', iv: iv }, keyResult, cipherArr).then((plainArr) => {
                    resolve(plainArr);
                });
            });
        });
    }

    public static cryptoJSEncrypt(key: string, iv: string, plainText: any): WordArray {
        const wordArray = CryptoJS.AES.encrypt(
            plainText,
            key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        return wordArray;
    }

    public static cryptoJSDecrypt(key: string, iv: string, cipherText: any): CryptoJS.DecryptedMessage {
        const decryptedMessage = CryptoJS.AES.decrypt(
            cipherText,
            key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        return decryptedMessage;
    }
}
