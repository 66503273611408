import { Injectable } from '@angular/core';

import { UserKeyCreateResource } from '../models/resources/user-key/user-key-create.resource';
import { CommonUtils } from '../utils/common-utils.class';
import { RSAUtils } from '../utils/rsa-utils.class';
import { ApiUserKeyService } from './api/api-user-key.service';

@Injectable()
export class KeyStoreService {
    // Fields
    constructor(
        private apiUserKeyService: ApiUserKeyService
    ) {
        if (!localStorage) {
            throw new Error('[AuthService] LocalStorage support is needed');
        }
    }

    /**
     * Registers a key to the localstore
     * @param privateKey
     */
    public async registerKey(privateKey: string) {
        return new Promise(async (resolve, reject) => {
            // Creating new key pair
            const jsencrypt = await RSAUtils.GenerateKeyPair();

            // Creating new userKey
            const createResource = new UserKeyCreateResource();
            createResource.privateKey = jsencrypt.getPrivateKey();

            // Calling API
            this.apiUserKeyService.create(createResource).subscribe((userKey) => {
                // Encrypts Private key
                const arr = CommonUtils.splitString(privateKey, 240, true);
                let privateKeyEncrypted = '';
                let first = true;
                arr.forEach(string => {
                    if (first) {
                        first = false;
                    } else {
                        privateKeyEncrypted += '|';
                    }

                    privateKeyEncrypted += RSAUtils.EncryptText(jsencrypt.getPublicKey(), string);
                });

                // Sets localstore
                this.setKey(privateKeyEncrypted);
                this.setMachineId(userKey.machineId);

                resolve(true);
            }, (error) => reject(error));
        });
    }

    /**
     * Tryes to pull the key from the API to unlock the localstored key
     */
    public openKey(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!this.hasKey()) {
                return reject('No key stored');
            }

            // Calling API
            this.apiUserKeyService.getByMachineId(this.getMachineId()).subscribe((userKey) => {
                const privateKeyEncrypted = this.getKey();
                const arr = privateKeyEncrypted.split('|');

                let privateKey: string;
                arr.forEach(privateKeyChunk => {
                    privateKey += RSAUtils.DecryptText(userKey.privateKey, privateKeyChunk);
                });

                return resolve(privateKey);
            }, (error) => {
                return reject(error);
            });
        });
    }

    /**
     * Will reset the store
     */
    public resetKey(): void {
        this.setKey(null);
        this.setMachineId(null);
    }

    /**
     * Checks if has stored key
     */
    public hasKey(): boolean {
        if (this.getKey() != null && this.getKey() !== '') {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Gets the stored machineId from localstorage
     */
    private getMachineId(): string {
        const machineId = localStorage.getItem('machineId');
        return machineId;
    }

    /**
     * Sets the stored machineId in localstorage
     * @param machineId An machineId, null removes machineId
     */
    private setMachineId(machineId: string): void {
        if (machineId === null) {
            localStorage.removeItem('machineId');
        } else {
            localStorage.setItem('machineId', machineId);
        }
    }

    /**
     * Gets the stored key from localstorage
     */
    private getKey(): string {
        const key = localStorage.getItem('key');
        return key;
    }

    /**
     * Sets the stored key in localstorage
     * @param privateKeyEncrypted An encrypted private key, null removes the key from localstorage
     */
    private setKey(privateKeyEncrypted: string): void {
        if (privateKeyEncrypted == null) {
            localStorage.removeItem('key');
        } else {
            localStorage.setItem('key', privateKeyEncrypted);
        }
    }
}
