export class FileUtils {
    public static fileToBase64String(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = async function() {
                resolve(String(this.result).split(',')[1]);
            };
            reader.readAsDataURL(file);
        });
    }

    /*
    public static fileToArrayBuffer(file: FilesPromise<ArrayBuffer> {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = async function() {
                resolve(ArrayBuffer(this.result));
            };
            reader.readAsArrayBuffer(file);
        });
    }
    */
}
