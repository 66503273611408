import { PersonResource } from './person.resource';
import { LanguageEnum } from '../language.enum';

export class PersonUpdateResource {
    public firstName: string;
    public lastName: string;
    public birthdate: Date;
    public socialSecurityNumber: string;
    public phoneNumber: string;
    public email: string;
    public alternativeEmail: string;
    public address: string;
    public zipcode: string;
    public city: string;
    public country: string;

    public language: LanguageEnum;

    constructor(item: PersonResource) {
        this.firstName = item.firstName;
        this.lastName = item.lastName;
        this.birthdate = item.birthdate;
        this.socialSecurityNumber = item.socialSecurityNumber;
        this.phoneNumber = item.phoneNumber;
        this.email = item.email;
        this.alternativeEmail = item.alternativeEmail;
        this.address = item.address;
        this.zipcode = item.zipcode;
        this.city = item.city;
        this.country = item.country;

        this.language = item.language;
    }
}
