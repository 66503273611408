import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    constructor(
        private translate: TranslateService
    ) {
        // Addes Language
        this.translate.addLangs(['Dansk', 'English']);
        
        // Sets browser language
        let browserLang = translate.getBrowserLang();
        if(browserLang == "da")
            this.translate.use('Dansk');
        else
            this.translate.use('English');
    }
}
