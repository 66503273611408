import { UserResource } from './user.resource';

export class ResourceBase {
    public id: number;

    public createdAt: Date;
    public createdById: number;
    public createdBy: UserResource;

    public modifiedAt: Date;
    public modifiedById: number;
    public modifiedBy: UserResource;
}
