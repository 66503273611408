import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { AdminModule } from './components/admin/admin.module';
import { DocumentCopyComponent } from './components/main/document-copy/document-copy.component';
import { MainComponent } from './components/main/main.component';
import { LanguageSelectionModule } from './components/shared/language-selection/language-selection.module';
import { NotFoundComponent } from './components/shared/not-found/not-found.component';
import { ApiApiService } from './services/api/api-api.service';
import { ApiPublicService } from './services/api/api-public.service';

// import ngx-translate and the http loader
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/languages/', '.json');
}

@NgModule({
    imports: [
        FormsModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgSelectModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        LanguageSelectionModule,
        ToastrModule.forRoot(),
        AppRouting,
        AdminModule,
    ],
    declarations: [
        AppComponent,
        MainComponent,
        DocumentCopyComponent,

        // Shared
        NotFoundComponent
    ],
    providers: [
        ApiApiService,
        ApiPublicService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
