import { DocumentTypeResource } from './document-type.resource';

export class DocumentTypeCreateResource {
    public nameDA: string;
    public nameEN: string;
    public description: string;

    constructor(resource:DocumentTypeResource) {
        this.nameDA = resource.nameDA;
        this.nameEN = resource.nameEN;
        this.description = resource.description;
    }
}