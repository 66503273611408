import { CasePersonResource } from '../case-person/case-person.resource';
import { ResourceBase } from '../resource-base.resource';
import { CaseCompanyCategoryEnum } from './case-company-category.enum';
import { CaseDivisionEnum } from './case-division.enum';
import { CaseStatusEnum } from './case-status.enum';
import { CaseTypeEnum } from './case-type.enum';

export class CaseResource extends ResourceBase {
    public caseNumber: string;
    public caseName: string;

    public type: CaseTypeEnum;
    public status: CaseStatusEnum;
    public division: CaseDivisionEnum;

    public active: boolean;

    public companyName: string;
    public companyCVRNumber: string;
    public companyAddress: string;
    public companyZipcode: string;
    public companyCity: string;
    public companyCountry: string;
    public companyCategory: CaseCompanyCategoryEnum;

    public message: string;

    public deleteAt: Date;

    public persons: Array<CasePersonResource>;

    constructor() {
        super();
        this.persons = new Array<CasePersonResource>();
    }
}
