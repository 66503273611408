import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LanguageSelectionComponent } from './language-selection.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        LanguageSelectionComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
    ],
    exports: [
        LanguageSelectionComponent
    ],
    providers: [],
})
export class LanguageSelectionModule {}
