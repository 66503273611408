import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { ApiApiService } from './../services/api/api-api.service';

@Injectable()
export class AdminAuthenticationGuard implements CanActivate {
    constructor(
        private _router: Router,
        private apiApiService: ApiApiService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.apiApiService.hasAccess().subscribe((message) => {
                observer.next(true);
                observer.complete();
            }, (error) => {
                console.error(error);
                observer.next(false);
                observer.complete();
            });
        });
    }
}
