import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { DocumentResource } from '../../../../models/resources/document/document.resource';
import { PersonResource } from '../../../../models/resources/person/person.resource';
import { ApiDocumentService } from '../../../../services/api/api-document.service';
import { ApiPersonService } from '../../../../services/api/api-person.service';

@Component({
    templateUrl: 'admin-person-documents.component.html'
})

export class AdminPersonDocumentsComponent implements OnInit {
    public itemId: any;
    public item: PersonResource;

    public documents: Array<DocumentResource>;

    public isLoading: boolean;

    constructor(
        public _location: Location,
        private _route: ActivatedRoute,
        private translate: TranslateService,
        private toastrService: ToastrService,
        private apiPersonService: ApiPersonService,
        private apiDocumentService: ApiDocumentService,
    ) { }

    public ngOnInit(): void {
        this.itemId = this._route.snapshot.params['itemId'];
        if (this.itemId !== 'new') {
            this.pullPerson();
        }

        //this.translateLanguage();
    }

    /**
     * Pulls the current person
     */
    public pullPerson(): void {
        this.isLoading = true;
        this.apiPersonService.get(this.itemId).subscribe((item) => {
            this.item = item;

            this.documents = new Array<DocumentResource>();

            this.item.casePersons.forEach((casePerson) => {
                casePerson.documents.forEach((document) => {
                    this.documents.push(document);
                });
            });
            
            this.isLoading = false;
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.toastrService.error(error.message, 'Failed to get Person!');
        });
    }

    
    /**
     * Will try to delete the document
     * @param item
     */
    public deleteDocument(document: DocumentResource): void {
        this.translate.get([
            'toasty.error.deletedtitle',
            'toasty.error.deletedmsg',
            'toasty.document.deletedtitle',
            'toasty.document.deletedmsg',
        ]).subscribe(translations => {
            this.apiDocumentService.delete(document.id).subscribe((result) => {
                this.toastrService.success(translations['toasty.document.deletedmsg'], translations['toasty.document.deletedtitle']);
                this.pullPerson();
            }, (error: HttpErrorResponse) => {
                this.toastrService.error(error.message, 'Failed to delete Document!');
                this.pullPerson();
            });
        });
    }
}