import { Component, OnInit } from '@angular/core';

import { KeyStoreService } from './../../../services/key-store.service';

@Component({
    templateUrl: './admin-home.component.html',
    styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {
    constructor(
        private keyStoreService: KeyStoreService
    ) { }

    public ngOnInit(): void {
       
    }
}
