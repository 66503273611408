import { CaseDivisionEnum } from './case-division.enum';
import { QueryResourceBase } from '../query-resource-base.resource';

export class CaseQueryResource extends QueryResourceBase {
    public caseNumber: string;
    public division: CaseDivisionEnum;
    public active: boolean;

    public casePersonId: number;
    public casePersonName: string;
    public casePersonEmail: string;
}
