import { Component, Input, OnInit } from '@angular/core';
import { DocumentStatusEnum } from '../../../../models/resources/document/document-status.enum';


@Component({
    selector: 'document-status-badge',
    template: `
        <span class="badge badge-warning" *ngIf="status == 0">File Uploaded</span>
        <span class="badge badge-danger" *ngIf="status == 1">File Afvist</span>
        <span class="badge badge-success" *ngIf="status == 2">File Accepteret</span>
    `,
    styles: [``]
})
export class DocumentStatusBadgeComponent {
    // Input
    @Input() status: DocumentStatusEnum;

    constructor() { }
}
